<template>
  <div
    :style="`background-image: url(${sideImg}); background-size: cover;`"
    class="h-100 text-white"
  >
    <b-row
      align-v="center"
      class="h-100 justify-content-center"
    >

      <b-col cols="6">
        <div class="text-center mb-2">
          <h1 class="font-weight-bolder text-white">
            {{ showData.name }}贊助系統
          </h1>
        </div>

        <div class="text-center mb-2">
          <h2 class="font-weight-bolder text-white">
            自助贊助中心, 快速金流繳費, 保障安全隱私
          </h2>
        </div>

        <hr>

        <div class="text-center mb-2">
          <h4 class="font-weight-bolder text-white">
            遊戲伺服器：【{{ showData.name }}】
          </h4>
        </div>

        <b-form-input
          v-model="orderData.account"
          placeholder="請輸入遊戲帳號"
          class="mb-2"
        />

        <cleave
          v-model="orderData.money"
          :options="{ numeral: true, numeralThousandsGroupStyle: 'no' }"
          placeholder="請輸入繳款金額"
          class="mb-2 form-control"
        />

        <!-- <b-form-select
          v-model="orderData.pt"
          :options="pays"
          class="mb-2"
        /> -->

        <b-form-select
          v-model="orderData.pt"
          :options="showData.payments || []"
          value-field="type"
          text-field="title"
          class="mb-2"
        >
          <template #first>
            <b-form-select-option
              disabled
              :value="null"
            >
              請選擇付款方式
            </b-form-select-option>
          </template>
        </b-form-select>

        <div class="text-center">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="dark"
            @click="$emit('on-submit', orderData)"
          >
            確定贊助
          </b-button>
        </div>

      </b-col>
    </b-row>

    <slot />
  </div>
</template>

<script>
import {
  BRow, BCol, BFormInput, BFormSelect, BFormSelectOption, BButton,
} from 'bootstrap-vue'
import { computed, ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    // BContainer,
    BRow,
    BCol,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BButton,

    Cleave,
  },
  directives: {
    Ripple,
  },
  props: {
    showData: {
      type: [Object, null],
      required: true,
    },
  },
  setup(props) {
    const orderData = ref({
      money: 300,
      // pt: 'ecpay_cvs',
      pt: null,
    })
    /* eslint-disable global-require */
    const sideImg = computed(() => props.showData.bg_image || require('@/assets/images/background/particles_bg.jpg'))

    const pays = [
      { text: '超商代碼繳費(最高繳款金額6000)-請選擇此繳費方式', value: 'ecpay_cvs' },
      { text: 'ATM轉帳繳費(最高繳款金額20000)-請選擇此繳費方式', value: 'ecpay_atm' },
      { text: '線上刷卡服務(最高繳款金額20000)-請選擇此繳費方式', value: 'ecpay_credit' },
    ]

    return {
      orderData,

      sideImg,
      pays,
    }
  },
}
</script>

<style lang="scss">
.form-control {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border-color: rgba(255, 255, 255, 0.3);

  &:focus {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.7);
    border-color: rgba(255, 255, 255, 0.3);
  }
}

.custom-select {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border-color: rgba(255, 255, 255, 0.3);

  &:focus {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.7);
    border-color: rgba(255, 255, 255, 0.3);
  }
}
</style>
